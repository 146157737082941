import classnames from "classnames";
import React, { CSSProperties } from "react";
import Heading, { Container } from "../Heading";

export interface Props {
  title: React.ReactNode;
  children?: React.ReactNode;
  subtitle?: React.ReactNode;
  color?: "white" | "black" | "transparent";
  layout?: "default" | "slim";
  className?: string;
  style?: CSSProperties;
}

const colorMap = {
  white: "bg-white text-mine-shaft",
  black: "bg-mine-shaft text-white",
  transparent: "bg-transparent text-white",
};

const Banner = ({ title, children, subtitle, color = "white", layout = "default", className, style }: Props) => {
  return (
    <div className={classnames("container mx-auto", className)} style={style}>
      <div
        className={classnames(`flex flex-col w-full text-center ${colorMap[color]}`, {
          "p-8": layout === "default",
          "p-4": layout === "slim",
        })}
      >
        <Container>
          <Heading
            className={classnames({
              "text-7xl": layout === "default",
              "text-6xl": layout === "slim",
            })}
            subtitle={subtitle}
          >
            {title}
          </Heading>
        </Container>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Banner;
